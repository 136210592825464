import { parseSearchState, SearchProvider } from '@faststore/sdk'
import { useSession } from 'src/sdk/session'
import { graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useEffect, useRef, useState } from 'react'
import Breadcrumb from 'src/components/sections/Breadcrumb'
import ProductGallery from 'src/components/sections/ProductGallery'
import SROnly from 'src/components/ui/SROnly'
import { ITEMS_PER_PAGE } from 'src/constants'
import { applySearchState } from 'src/sdk/search/state'
import { usePageViewEvent } from 'src/sdk/analytics/hooks/usePageView'
import { mark } from 'src/sdk/tests/mark'
import type { SearchState } from '@faststore/sdk'
import type { PageProps } from 'gatsby'
import type {
  SearchPageQueryQuery,
  SearchPageQueryQueryVariables,
} from '@generated/graphql'

import 'src/styles/pages/search.scss'
// import type { RawDraftContentState } from 'draft-js'

import { storeUrl } from '../../store.config'

export type Props = PageProps<
  SearchPageQueryQuery,
  SearchPageQueryQueryVariables
>

const useSearchParams = ({ href }: Location) => {
  const [params, setParams] = useState<SearchState | null>(null)

  useEffect(() => {
    const url = new URL(href)

    setParams(parseSearchState(url))
  }, [href])

  return params
}

function Page(props: Props) {
  const {
    data: {
      site,
      // allCmsPromotionflags: {
      //   edges: [
      //     {
      //       node: {
      //         sections: [
      //           {
      //             data: { content },
      //           },
      //         ],
      //       },
      //     },
      //   ],
      // },
    },
  } = props

  // const parsedContent = JSON.parse(content) as RawDraftContentState
  // const flagsCSS = parsedContent.blocks
  //   .map((block) => (!block.text.trim() && '\n') || block.text)
  //   .join('\n')

  const { locale } = useSession()
  const searchParams = useSearchParams(props.location)
  const title = site?.siteMetadata?.title ?? 'Search Result'
  const { sendpageViewEvent } = usePageViewEvent()
  const viewedOnce = useRef(false)

  if (!searchParams) {
    return null
  }

  const PageViewEvent = () => {
    if (typeof window !== 'undefined') {
      !viewedOnce.current &&
        sendpageViewEvent({
          location: window?.location?.href ?? '/',
          page: 'Search',
          title,
          originalLocation: storeUrl,
        })

      viewedOnce.current = true
    }

    return <></>
  }

  return (
    <SearchProvider
      onChange={applySearchState}
      itemsPerPage={ITEMS_PER_PAGE}
      {...searchParams}
    >
      {/* <style type="text/css" dangerouslySetInnerHTML={{ __html: flagsCSS }} /> */}
      {/* SEO */}
      <GatsbySeo
        noindex
        language={locale}
        title={title}
        description={site?.siteMetadata?.description ?? ''}
        titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
        openGraph={{
          type: 'website',
          title,
          description: site?.siteMetadata?.description ?? '',
        }}
      />
      <SROnly as="h1" text={title} />
      <PageViewEvent />
      {/*
        WARNING: Do not import or render components from any
        other folder than '../components/sections' in here.

        This is necessary to keep the integration with the CMS
        easy and consistent, enabling the change and reorder
        of elements on this page.

        If needed, wrap your component in a <Section /> component
        (not the HTML tag) before rendering it here.
      */}
      <Breadcrumb name="Todos los productos" />
      <ProductGallery
        title="Resultados de la búsqueda"
        searchTerm={searchParams.term ?? undefined}
        useFilters
      />
    </SearchProvider>
  )
}

export const querySSG = graphql`
  query SearchPageQuery {
    allCmsPromotionflags {
      edges {
        node {
          sections {
            data
          }
        }
      }
    }
    site {
      siteMetadata {
        titleTemplate
        title
        description
      }
    }
  }
`

Page.displayName = 'Page'

export default mark(Page)

export { Head } from 'src/components/common/Head'
